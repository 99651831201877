import { Box, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import React from 'react';
import { renderContentBlocks } from '.';
import { CraftGql_Body_NewsLetter_BlockType, FaqsPageQueryQuery } from '../../graphql-types';
import { LL_BACKGROUND_COLOR } from '../colors';
import Layout from '../components/layout';
import { Absolute } from '../components/primitives/absolute';
import { Width80Box } from '../components/primitives/Width80Box';
import { ReusableComponentsType } from '../componentsEnum';
import { NewsLetterSection } from '../sections/news-letter-section';

export interface FaqsProps {
  data: FaqsPageQueryQuery;
}

const Faqs = (props: FaqsProps) => {
  const { data } = props;
  const newsLetterData = data.craftGqlFaqsFaqsEntry.body.filter(
    (item) => 'typeHandle' in item && item.typeHandle === ReusableComponentsType.newsLetter
  )[0];
  const theme = useTheme();
  console.log('data is', data.craftGqlFaqsFaqsEntry.body);
  return (
    <Box>
      {data.craftGqlFaqsFaqsEntry.body.map(
        (item) =>
          'typeHandle' in item &&
          item.typeHandle !== ReusableComponentsType.newsLetter &&
          renderContentBlocks(item, theme)
      )}
      <Box bgcolor={LL_BACKGROUND_COLOR} width="100%">
        <Width80Box
          sx={{
            maxWidth: '952px'
          }}
        >
          <NewsLetterSection
            item={newsLetterData as CraftGql_Body_NewsLetter_BlockType}
            theme={theme}
          />
        </Width80Box>
        <Box position="relative">
          <Absolute
            height="250px"
            width="100%"
            zIndex={-1}
            bgcolor={LL_BACKGROUND_COLOR}
          ></Absolute>
        </Box>
      </Box>
    </Box>
  );
};

Faqs.Layout = Layout;
Faqs.isNavbarBackgroundEmpty = true;

export const query = graphql`
  query FaqsPageQuery {
    craftGqlFaqsFaqsEntry {
      body {
        ... on CraftGQL_body_faqs_BlockType {
          id
          textTitle
          typeHandle
          uid
          faqs {
            ... on CraftGQL_faqs_faq_BlockType {
              id
              faqQuestion
              faqAnswer
              faqFor
            }
          }
        }
        ... on CraftGQL_body_newsLetter_BlockType {
          id
          typeHandle
          newsLetterSection {
            ... on CraftGQL_newsLetterSection_newsLetterSection_BlockType {
              id
              newsButtonText
              newsImage {
                url
              }
              newsSubtitle
              newsTitle
            }
          }
        }
      }
    }
  }
`;
export default Faqs;
